import routerOptions0 from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_5estx2tojukuyv3c5deucp6dbm/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/home/jenkins/agent/workspace/platform-static front-category-prod/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}