
// @ts-nocheck


export const localeCodes =  [
  "au",
  "de",
  "de-en",
  "en",
  "es",
  "mx",
  "fr",
  "it",
  "jp",
  "ru",
  "sg",
  "uk"
]

export const localeLoaders = {
  "au": [{ key: "../lang/au/common/header.ts", load: () => import("../lang/au/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_common_header_ts" */), cache: true },
{ key: "../lang/au/common/footer.ts", load: () => import("../lang/au/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_common_footer_ts" */), cache: true },
{ key: "../lang/au/common/addCartPopup.ts", load: () => import("../lang/au/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/au/common/ContactSales.ts", load: () => import("../lang/au/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_common_ContactSales_ts" */), cache: true },
{ key: "../lang/au/common/error.ts", load: () => import("../lang/au/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_common_error_ts" */), cache: true },
{ key: "../lang/au/common/cookieTip.ts", load: () => import("../lang/au/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_common_cookieTip_ts" */), cache: true },
{ key: "../lang/au/common/telCode.ts", load: () => import("../lang/au/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_common_telCode_ts" */), cache: true },
{ key: "../lang/au/common/SelectCountry.ts", load: () => import("../lang/au/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/au/common/formValidate.ts", load: () => import("../lang/au/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_common_formValidate_ts" */), cache: true },
{ key: "../lang/au/common/contactUs.ts", load: () => import("../lang/au/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_common_contactUs_ts" */), cache: true },
{ key: "../lang/au/basic/home.ts", load: () => import("../lang/au/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_basic_home_ts" */), cache: true },
{ key: "../lang/au/basic/firstCategory.ts", load: () => import("../lang/au/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/au/basic/secondCategory.ts", load: () => import("../lang/au/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/au/basic/caseStudy.ts", load: () => import("../lang/au/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/au/basic/blog.ts", load: () => import("../lang/au/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_basic_blog_ts" */), cache: true },
{ key: "../lang/au/account/checkout.ts", load: () => import("../lang/au/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_account_checkout_ts" */), cache: true },
{ key: "../lang/au/account/checkoutAgainst.ts", load: () => import("../lang/au/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/au/account/pay.ts", load: () => import("../lang/au/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_account_pay_ts" */), cache: true },
{ key: "../lang/au/support/contactSales.ts", load: () => import("../lang/au/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_support_contactSales_ts" */), cache: true },
{ key: "../lang/au/support/requestTechSupport.ts", load: () => import("../lang/au/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/au/support/technicalDocuments.ts", load: () => import("../lang/au/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/au/support/mtpDensity.ts", load: () => import("../lang/au/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/au/support/picOSVUserGuide.ts", load: () => import("../lang/au/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/au/support/fmtSeries.ts", load: () => import("../lang/au/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/au/support/solutionDemoTest.ts", load: () => import("../lang/au/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/au/popup/address.ts", load: () => import("../lang/au/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_au_popup_address_ts" */), cache: true }],
  "de": [{ key: "../lang/de/common/header.ts", load: () => import("../lang/de/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_common_header_ts" */), cache: true },
{ key: "../lang/de/common/footer.ts", load: () => import("../lang/de/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_common_footer_ts" */), cache: true },
{ key: "../lang/de/common/addCartPopup.ts", load: () => import("../lang/de/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/de/common/ContactSales.ts", load: () => import("../lang/de/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_common_ContactSales_ts" */), cache: true },
{ key: "../lang/de/common/error.ts", load: () => import("../lang/de/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_common_error_ts" */), cache: true },
{ key: "../lang/de/common/cookieTip.ts", load: () => import("../lang/de/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_common_cookieTip_ts" */), cache: true },
{ key: "../lang/de/common/telCode.ts", load: () => import("../lang/de/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_common_telCode_ts" */), cache: true },
{ key: "../lang/de/common/SelectCountry.ts", load: () => import("../lang/de/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/de/common/formValidate.ts", load: () => import("../lang/de/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_common_formValidate_ts" */), cache: true },
{ key: "../lang/de/common/contactUs.ts", load: () => import("../lang/de/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_common_contactUs_ts" */), cache: true },
{ key: "../lang/de/basic/home.ts", load: () => import("../lang/de/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_basic_home_ts" */), cache: true },
{ key: "../lang/de/basic/firstCategory.ts", load: () => import("../lang/de/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/de/basic/secondCategory.ts", load: () => import("../lang/de/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/de/basic/caseStudy.ts", load: () => import("../lang/de/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/de/basic/blog.ts", load: () => import("../lang/de/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_basic_blog_ts" */), cache: true },
{ key: "../lang/de/account/checkout.ts", load: () => import("../lang/de/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_account_checkout_ts" */), cache: true },
{ key: "../lang/de/account/checkoutAgainst.ts", load: () => import("../lang/de/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/de/account/pay.ts", load: () => import("../lang/de/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_account_pay_ts" */), cache: true },
{ key: "../lang/de/support/contactSales.ts", load: () => import("../lang/de/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_support_contactSales_ts" */), cache: true },
{ key: "../lang/de/support/requestTechSupport.ts", load: () => import("../lang/de/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/de/support/technicalDocuments.ts", load: () => import("../lang/de/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/de/support/mtpDensity.ts", load: () => import("../lang/de/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/de/support/picOSVUserGuide.ts", load: () => import("../lang/de/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/de/support/fmtSeries.ts", load: () => import("../lang/de/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/de/support/solutionDemoTest.ts", load: () => import("../lang/de/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/de/popup/address.ts", load: () => import("../lang/de/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_popup_address_ts" */), cache: true }],
  "de-en": [{ key: "../lang/de-en/common/header.ts", load: () => import("../lang/de-en/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_common_header_ts" */), cache: true },
{ key: "../lang/de-en/common/footer.ts", load: () => import("../lang/de-en/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_common_footer_ts" */), cache: true },
{ key: "../lang/de-en/common/addCartPopup.ts", load: () => import("../lang/de-en/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/de-en/common/ContactSales.ts", load: () => import("../lang/de-en/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_common_ContactSales_ts" */), cache: true },
{ key: "../lang/de-en/common/error.ts", load: () => import("../lang/de-en/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_common_error_ts" */), cache: true },
{ key: "../lang/de-en/common/cookieTip.ts", load: () => import("../lang/de-en/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_common_cookieTip_ts" */), cache: true },
{ key: "../lang/de-en/common/telCode.ts", load: () => import("../lang/de-en/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_common_telCode_ts" */), cache: true },
{ key: "../lang/de-en/common/SelectCountry.ts", load: () => import("../lang/de-en/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/de-en/common/formValidate.ts", load: () => import("../lang/de-en/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_common_formValidate_ts" */), cache: true },
{ key: "../lang/de-en/common/contactUs.ts", load: () => import("../lang/de-en/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_common_contactUs_ts" */), cache: true },
{ key: "../lang/de-en/basic/home.ts", load: () => import("../lang/de-en/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_basic_home_ts" */), cache: true },
{ key: "../lang/de-en/basic/firstCategory.ts", load: () => import("../lang/de-en/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/de-en/basic/secondCategory.ts", load: () => import("../lang/de-en/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/de-en/basic/caseStudy.ts", load: () => import("../lang/de-en/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/de-en/basic/blog.ts", load: () => import("../lang/de-en/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_basic_blog_ts" */), cache: true },
{ key: "../lang/de-en/account/checkout.ts", load: () => import("../lang/de-en/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_account_checkout_ts" */), cache: true },
{ key: "../lang/de-en/account/checkoutAgainst.ts", load: () => import("../lang/de-en/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/de-en/account/pay.ts", load: () => import("../lang/de-en/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_account_pay_ts" */), cache: true },
{ key: "../lang/de-en/support/contactSales.ts", load: () => import("../lang/de-en/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_support_contactSales_ts" */), cache: true },
{ key: "../lang/de-en/support/requestTechSupport.ts", load: () => import("../lang/de-en/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/de-en/support/technicalDocuments.ts", load: () => import("../lang/de-en/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/de-en/support/mtpDensity.ts", load: () => import("../lang/de-en/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/de-en/support/picOSVUserGuide.ts", load: () => import("../lang/de-en/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/de-en/support/fmtSeries.ts", load: () => import("../lang/de-en/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/de-en/support/solutionDemoTest.ts", load: () => import("../lang/de-en/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/de-en/popup/address.ts", load: () => import("../lang/de-en/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_de_en_popup_address_ts" */), cache: true }],
  "en": [{ key: "../lang/en/common/header.ts", load: () => import("../lang/en/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_common_header_ts" */), cache: true },
{ key: "../lang/en/common/footer.ts", load: () => import("../lang/en/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_common_footer_ts" */), cache: true },
{ key: "../lang/en/common/addCartPopup.ts", load: () => import("../lang/en/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/en/common/ContactSales.ts", load: () => import("../lang/en/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_common_ContactSales_ts" */), cache: true },
{ key: "../lang/en/common/error.ts", load: () => import("../lang/en/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_common_error_ts" */), cache: true },
{ key: "../lang/en/common/cookieTip.ts", load: () => import("../lang/en/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_common_cookieTip_ts" */), cache: true },
{ key: "../lang/en/common/telCode.ts", load: () => import("../lang/en/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_common_telCode_ts" */), cache: true },
{ key: "../lang/en/common/SelectCountry.ts", load: () => import("../lang/en/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/en/common/formValidate.ts", load: () => import("../lang/en/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_common_formValidate_ts" */), cache: true },
{ key: "../lang/en/common/contactUs.ts", load: () => import("../lang/en/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_common_contactUs_ts" */), cache: true },
{ key: "../lang/en/basic/home.ts", load: () => import("../lang/en/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_basic_home_ts" */), cache: true },
{ key: "../lang/en/basic/firstCategory.ts", load: () => import("../lang/en/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/en/basic/secondCategory.ts", load: () => import("../lang/en/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/en/basic/caseStudy.ts", load: () => import("../lang/en/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/en/basic/blog.ts", load: () => import("../lang/en/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_basic_blog_ts" */), cache: true },
{ key: "../lang/en/account/checkout.ts", load: () => import("../lang/en/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_account_checkout_ts" */), cache: true },
{ key: "../lang/en/account/checkoutAgainst.ts", load: () => import("../lang/en/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/en/account/pay.ts", load: () => import("../lang/en/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_account_pay_ts" */), cache: true },
{ key: "../lang/en/support/contactSales.ts", load: () => import("../lang/en/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_support_contactSales_ts" */), cache: true },
{ key: "../lang/en/support/requestTechSupport.ts", load: () => import("../lang/en/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/en/support/technicalDocuments.ts", load: () => import("../lang/en/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/en/support/mtpDensity.ts", load: () => import("../lang/en/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/en/support/picOSVUserGuide.ts", load: () => import("../lang/en/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/en/support/fmtSeries.ts", load: () => import("../lang/en/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/en/support/solutionDemoTest.ts", load: () => import("../lang/en/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/en/popup/address.ts", load: () => import("../lang/en/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_en_popup_address_ts" */), cache: true }],
  "es": [{ key: "../lang/es/common/header.ts", load: () => import("../lang/es/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_header_ts" */), cache: true },
{ key: "../lang/es/common/footer.ts", load: () => import("../lang/es/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_footer_ts" */), cache: true },
{ key: "../lang/es/common/addCartPopup.ts", load: () => import("../lang/es/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/es/common/ContactSales.ts", load: () => import("../lang/es/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_ContactSales_ts" */), cache: true },
{ key: "../lang/es/common/error.ts", load: () => import("../lang/es/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_error_ts" */), cache: true },
{ key: "../lang/es/common/cookieTip.ts", load: () => import("../lang/es/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_cookieTip_ts" */), cache: true },
{ key: "../lang/es/common/telCode.ts", load: () => import("../lang/es/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_telCode_ts" */), cache: true },
{ key: "../lang/es/common/SelectCountry.ts", load: () => import("../lang/es/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/es/common/formValidate.ts", load: () => import("../lang/es/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_formValidate_ts" */), cache: true },
{ key: "../lang/es/common/contactUs.ts", load: () => import("../lang/es/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_contactUs_ts" */), cache: true },
{ key: "../lang/es/basic/home.ts", load: () => import("../lang/es/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_basic_home_ts" */), cache: true },
{ key: "../lang/es/basic/firstCategory.ts", load: () => import("../lang/es/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/es/basic/secondCategory.ts", load: () => import("../lang/es/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/es/basic/caseStudy.ts", load: () => import("../lang/es/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/es/basic/blog.ts", load: () => import("../lang/es/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_basic_blog_ts" */), cache: true },
{ key: "../lang/es/account/checkout.ts", load: () => import("../lang/es/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_account_checkout_ts" */), cache: true },
{ key: "../lang/es/account/checkoutAgainst.ts", load: () => import("../lang/es/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/es/account/pay.ts", load: () => import("../lang/es/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_account_pay_ts" */), cache: true },
{ key: "../lang/es/support/contactSales.ts", load: () => import("../lang/es/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_contactSales_ts" */), cache: true },
{ key: "../lang/es/support/requestTechSupport.ts", load: () => import("../lang/es/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/es/support/technicalDocuments.ts", load: () => import("../lang/es/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/es/support/mtpDensity.ts", load: () => import("../lang/es/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/es/support/picOSVUserGuide.ts", load: () => import("../lang/es/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/es/support/fmtSeries.ts", load: () => import("../lang/es/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/es/support/solutionDemoTest.ts", load: () => import("../lang/es/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/es/popup/address.ts", load: () => import("../lang/es/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_popup_address_ts" */), cache: true }],
  "mx": [{ key: "../lang/es/common/header.ts", load: () => import("../lang/es/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_header_ts" */), cache: true },
{ key: "../lang/es/common/footer.ts", load: () => import("../lang/es/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_footer_ts" */), cache: true },
{ key: "../lang/es/common/addCartPopup.ts", load: () => import("../lang/es/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/es/common/ContactSales.ts", load: () => import("../lang/es/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_ContactSales_ts" */), cache: true },
{ key: "../lang/es/common/error.ts", load: () => import("../lang/es/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_error_ts" */), cache: true },
{ key: "../lang/es/common/cookieTip.ts", load: () => import("../lang/es/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_cookieTip_ts" */), cache: true },
{ key: "../lang/es/common/telCode.ts", load: () => import("../lang/es/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_telCode_ts" */), cache: true },
{ key: "../lang/es/common/SelectCountry.ts", load: () => import("../lang/es/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/es/common/formValidate.ts", load: () => import("../lang/es/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_formValidate_ts" */), cache: true },
{ key: "../lang/es/common/contactUs.ts", load: () => import("../lang/es/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_common_contactUs_ts" */), cache: true },
{ key: "../lang/es/basic/home.ts", load: () => import("../lang/es/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_basic_home_ts" */), cache: true },
{ key: "../lang/es/basic/firstCategory.ts", load: () => import("../lang/es/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/es/basic/secondCategory.ts", load: () => import("../lang/es/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/es/basic/caseStudy.ts", load: () => import("../lang/es/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/es/basic/blog.ts", load: () => import("../lang/es/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_basic_blog_ts" */), cache: true },
{ key: "../lang/es/account/checkout.ts", load: () => import("../lang/es/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_account_checkout_ts" */), cache: true },
{ key: "../lang/es/account/checkoutAgainst.ts", load: () => import("../lang/es/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/es/account/pay.ts", load: () => import("../lang/es/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_account_pay_ts" */), cache: true },
{ key: "../lang/es/support/contactSales.ts", load: () => import("../lang/es/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_contactSales_ts" */), cache: true },
{ key: "../lang/es/support/requestTechSupport.ts", load: () => import("../lang/es/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/es/support/technicalDocuments.ts", load: () => import("../lang/es/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/es/support/mtpDensity.ts", load: () => import("../lang/es/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/es/support/picOSVUserGuide.ts", load: () => import("../lang/es/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/es/support/fmtSeries.ts", load: () => import("../lang/es/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/es/support/solutionDemoTest.ts", load: () => import("../lang/es/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/es/popup/address.ts", load: () => import("../lang/es/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_es_popup_address_ts" */), cache: true }],
  "fr": [{ key: "../lang/fr/common/header.ts", load: () => import("../lang/fr/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_common_header_ts" */), cache: true },
{ key: "../lang/fr/common/footer.ts", load: () => import("../lang/fr/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_common_footer_ts" */), cache: true },
{ key: "../lang/fr/common/addCartPopup.ts", load: () => import("../lang/fr/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/fr/common/ContactSales.ts", load: () => import("../lang/fr/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_common_ContactSales_ts" */), cache: true },
{ key: "../lang/fr/common/error.ts", load: () => import("../lang/fr/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_common_error_ts" */), cache: true },
{ key: "../lang/fr/common/cookieTip.ts", load: () => import("../lang/fr/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_common_cookieTip_ts" */), cache: true },
{ key: "../lang/fr/common/telCode.ts", load: () => import("../lang/fr/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_common_telCode_ts" */), cache: true },
{ key: "../lang/fr/common/SelectCountry.ts", load: () => import("../lang/fr/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/fr/common/formValidate.ts", load: () => import("../lang/fr/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_common_formValidate_ts" */), cache: true },
{ key: "../lang/fr/common/contactUs.ts", load: () => import("../lang/fr/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_common_contactUs_ts" */), cache: true },
{ key: "../lang/fr/basic/home.ts", load: () => import("../lang/fr/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_basic_home_ts" */), cache: true },
{ key: "../lang/fr/basic/firstCategory.ts", load: () => import("../lang/fr/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/fr/basic/secondCategory.ts", load: () => import("../lang/fr/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/fr/basic/caseStudy.ts", load: () => import("../lang/fr/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/fr/basic/blog.ts", load: () => import("../lang/fr/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_basic_blog_ts" */), cache: true },
{ key: "../lang/fr/account/checkout.ts", load: () => import("../lang/fr/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_account_checkout_ts" */), cache: true },
{ key: "../lang/fr/account/checkoutAgainst.ts", load: () => import("../lang/fr/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/fr/account/pay.ts", load: () => import("../lang/fr/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_account_pay_ts" */), cache: true },
{ key: "../lang/fr/support/contactSales.ts", load: () => import("../lang/fr/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_support_contactSales_ts" */), cache: true },
{ key: "../lang/fr/support/requestTechSupport.ts", load: () => import("../lang/fr/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/fr/support/technicalDocuments.ts", load: () => import("../lang/fr/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/fr/support/mtpDensity.ts", load: () => import("../lang/fr/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/fr/support/picOSVUserGuide.ts", load: () => import("../lang/fr/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/fr/support/fmtSeries.ts", load: () => import("../lang/fr/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/fr/support/solutionDemoTest.ts", load: () => import("../lang/fr/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/fr/popup/address.ts", load: () => import("../lang/fr/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_fr_popup_address_ts" */), cache: true }],
  "it": [{ key: "../lang/it/common/header.ts", load: () => import("../lang/it/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_common_header_ts" */), cache: true },
{ key: "../lang/it/common/footer.ts", load: () => import("../lang/it/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_common_footer_ts" */), cache: true },
{ key: "../lang/it/common/addCartPopup.ts", load: () => import("../lang/it/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/it/common/ContactSales.ts", load: () => import("../lang/it/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_common_ContactSales_ts" */), cache: true },
{ key: "../lang/it/common/error.ts", load: () => import("../lang/it/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_common_error_ts" */), cache: true },
{ key: "../lang/it/common/cookieTip.ts", load: () => import("../lang/it/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_common_cookieTip_ts" */), cache: true },
{ key: "../lang/it/common/telCode.ts", load: () => import("../lang/it/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_common_telCode_ts" */), cache: true },
{ key: "../lang/it/common/SelectCountry.ts", load: () => import("../lang/it/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/it/common/formValidate.ts", load: () => import("../lang/it/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_common_formValidate_ts" */), cache: true },
{ key: "../lang/it/common/contactUs.ts", load: () => import("../lang/it/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_common_contactUs_ts" */), cache: true },
{ key: "../lang/it/basic/home.ts", load: () => import("../lang/it/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_basic_home_ts" */), cache: true },
{ key: "../lang/it/basic/firstCategory.ts", load: () => import("../lang/it/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/it/basic/secondCategory.ts", load: () => import("../lang/it/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/it/basic/caseStudy.ts", load: () => import("../lang/it/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/it/basic/blog.ts", load: () => import("../lang/it/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_basic_blog_ts" */), cache: true },
{ key: "../lang/it/account/checkout.ts", load: () => import("../lang/it/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_account_checkout_ts" */), cache: true },
{ key: "../lang/it/account/checkoutAgainst.ts", load: () => import("../lang/it/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/it/account/pay.ts", load: () => import("../lang/it/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_account_pay_ts" */), cache: true },
{ key: "../lang/it/support/contactSales.ts", load: () => import("../lang/it/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_support_contactSales_ts" */), cache: true },
{ key: "../lang/it/support/requestTechSupport.ts", load: () => import("../lang/it/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/it/support/technicalDocuments.ts", load: () => import("../lang/it/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/it/support/mtpDensity.ts", load: () => import("../lang/it/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/it/support/picOSVUserGuide.ts", load: () => import("../lang/it/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/it/support/fmtSeries.ts", load: () => import("../lang/it/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/it/support/solutionDemoTest.ts", load: () => import("../lang/it/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/it/popup/address.ts", load: () => import("../lang/it/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_it_popup_address_ts" */), cache: true }],
  "jp": [{ key: "../lang/jp/common/header.ts", load: () => import("../lang/jp/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_common_header_ts" */), cache: true },
{ key: "../lang/jp/common/footer.ts", load: () => import("../lang/jp/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_common_footer_ts" */), cache: true },
{ key: "../lang/jp/common/addCartPopup.ts", load: () => import("../lang/jp/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/jp/common/ContactSales.ts", load: () => import("../lang/jp/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_common_ContactSales_ts" */), cache: true },
{ key: "../lang/jp/common/error.ts", load: () => import("../lang/jp/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_common_error_ts" */), cache: true },
{ key: "../lang/jp/common/cookieTip.ts", load: () => import("../lang/jp/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_common_cookieTip_ts" */), cache: true },
{ key: "../lang/jp/common/telCode.ts", load: () => import("../lang/jp/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_common_telCode_ts" */), cache: true },
{ key: "../lang/jp/common/SelectCountry.ts", load: () => import("../lang/jp/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/jp/common/formValidate.ts", load: () => import("../lang/jp/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_common_formValidate_ts" */), cache: true },
{ key: "../lang/jp/common/contactUs.ts", load: () => import("../lang/jp/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_common_contactUs_ts" */), cache: true },
{ key: "../lang/jp/basic/home.ts", load: () => import("../lang/jp/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_basic_home_ts" */), cache: true },
{ key: "../lang/jp/basic/firstCategory.ts", load: () => import("../lang/jp/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/jp/basic/secondCategory.ts", load: () => import("../lang/jp/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/jp/basic/caseStudy.ts", load: () => import("../lang/jp/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/jp/basic/blog.ts", load: () => import("../lang/jp/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_basic_blog_ts" */), cache: true },
{ key: "../lang/jp/account/checkout.ts", load: () => import("../lang/jp/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_account_checkout_ts" */), cache: true },
{ key: "../lang/jp/account/checkoutAgainst.ts", load: () => import("../lang/jp/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/jp/account/pay.ts", load: () => import("../lang/jp/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_account_pay_ts" */), cache: true },
{ key: "../lang/jp/support/contactSales.ts", load: () => import("../lang/jp/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_support_contactSales_ts" */), cache: true },
{ key: "../lang/jp/support/requestTechSupport.ts", load: () => import("../lang/jp/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/jp/support/technicalDocuments.ts", load: () => import("../lang/jp/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/jp/support/mtpDensity.ts", load: () => import("../lang/jp/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/jp/support/picOSVUserGuide.ts", load: () => import("../lang/jp/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/jp/support/fmtSeries.ts", load: () => import("../lang/jp/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/jp/support/solutionDemoTest.ts", load: () => import("../lang/jp/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/jp/popup/address.ts", load: () => import("../lang/jp/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_jp_popup_address_ts" */), cache: true }],
  "ru": [{ key: "../lang/ru/common/header.ts", load: () => import("../lang/ru/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_common_header_ts" */), cache: true },
{ key: "../lang/ru/common/footer.ts", load: () => import("../lang/ru/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_common_footer_ts" */), cache: true },
{ key: "../lang/ru/common/addCartPopup.ts", load: () => import("../lang/ru/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/ru/common/ContactSales.ts", load: () => import("../lang/ru/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_common_ContactSales_ts" */), cache: true },
{ key: "../lang/ru/common/error.ts", load: () => import("../lang/ru/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_common_error_ts" */), cache: true },
{ key: "../lang/ru/common/cookieTip.ts", load: () => import("../lang/ru/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_common_cookieTip_ts" */), cache: true },
{ key: "../lang/ru/common/telCode.ts", load: () => import("../lang/ru/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_common_telCode_ts" */), cache: true },
{ key: "../lang/ru/common/SelectCountry.ts", load: () => import("../lang/ru/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/ru/common/formValidate.ts", load: () => import("../lang/ru/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_common_formValidate_ts" */), cache: true },
{ key: "../lang/ru/common/contactUs.ts", load: () => import("../lang/ru/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_common_contactUs_ts" */), cache: true },
{ key: "../lang/ru/basic/home.ts", load: () => import("../lang/ru/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_basic_home_ts" */), cache: true },
{ key: "../lang/ru/basic/firstCategory.ts", load: () => import("../lang/ru/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/ru/basic/secondCategory.ts", load: () => import("../lang/ru/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/ru/basic/caseStudy.ts", load: () => import("../lang/ru/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/ru/basic/blog.ts", load: () => import("../lang/ru/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_basic_blog_ts" */), cache: true },
{ key: "../lang/ru/account/checkout.ts", load: () => import("../lang/ru/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_account_checkout_ts" */), cache: true },
{ key: "../lang/ru/account/checkoutAgainst.ts", load: () => import("../lang/ru/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/ru/account/pay.ts", load: () => import("../lang/ru/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_account_pay_ts" */), cache: true },
{ key: "../lang/ru/support/contactSales.ts", load: () => import("../lang/ru/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_support_contactSales_ts" */), cache: true },
{ key: "../lang/ru/support/requestTechSupport.ts", load: () => import("../lang/ru/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/ru/support/technicalDocuments.ts", load: () => import("../lang/ru/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/ru/support/mtpDensity.ts", load: () => import("../lang/ru/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/ru/support/picOSVUserGuide.ts", load: () => import("../lang/ru/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/ru/support/fmtSeries.ts", load: () => import("../lang/ru/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/ru/support/solutionDemoTest.ts", load: () => import("../lang/ru/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/ru/popup/address.ts", load: () => import("../lang/ru/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_ru_popup_address_ts" */), cache: true }],
  "sg": [{ key: "../lang/sg/common/header.ts", load: () => import("../lang/sg/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_common_header_ts" */), cache: true },
{ key: "../lang/sg/common/footer.ts", load: () => import("../lang/sg/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_common_footer_ts" */), cache: true },
{ key: "../lang/sg/common/addCartPopup.ts", load: () => import("../lang/sg/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/sg/common/ContactSales.ts", load: () => import("../lang/sg/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_common_ContactSales_ts" */), cache: true },
{ key: "../lang/sg/common/error.ts", load: () => import("../lang/sg/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_common_error_ts" */), cache: true },
{ key: "../lang/sg/common/cookieTip.ts", load: () => import("../lang/sg/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_common_cookieTip_ts" */), cache: true },
{ key: "../lang/sg/common/telCode.ts", load: () => import("../lang/sg/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_common_telCode_ts" */), cache: true },
{ key: "../lang/sg/common/SelectCountry.ts", load: () => import("../lang/sg/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/sg/common/formValidate.ts", load: () => import("../lang/sg/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_common_formValidate_ts" */), cache: true },
{ key: "../lang/sg/common/contactUs.ts", load: () => import("../lang/sg/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_common_contactUs_ts" */), cache: true },
{ key: "../lang/sg/basic/home.ts", load: () => import("../lang/sg/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_basic_home_ts" */), cache: true },
{ key: "../lang/sg/basic/firstCategory.ts", load: () => import("../lang/sg/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/sg/basic/secondCategory.ts", load: () => import("../lang/sg/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/sg/basic/caseStudy.ts", load: () => import("../lang/sg/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/sg/basic/blog.ts", load: () => import("../lang/sg/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_basic_blog_ts" */), cache: true },
{ key: "../lang/sg/account/checkout.ts", load: () => import("../lang/sg/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_account_checkout_ts" */), cache: true },
{ key: "../lang/sg/account/checkoutAgainst.ts", load: () => import("../lang/sg/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/sg/account/pay.ts", load: () => import("../lang/sg/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_account_pay_ts" */), cache: true },
{ key: "../lang/sg/support/contactSales.ts", load: () => import("../lang/sg/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_support_contactSales_ts" */), cache: true },
{ key: "../lang/sg/support/requestTechSupport.ts", load: () => import("../lang/sg/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/sg/support/technicalDocuments.ts", load: () => import("../lang/sg/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/sg/support/mtpDensity.ts", load: () => import("../lang/sg/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/sg/support/picOSVUserGuide.ts", load: () => import("../lang/sg/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/sg/support/fmtSeries.ts", load: () => import("../lang/sg/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/sg/support/solutionDemoTest.ts", load: () => import("../lang/sg/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/sg/popup/address.ts", load: () => import("../lang/sg/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_sg_popup_address_ts" */), cache: true }],
  "uk": [{ key: "../lang/uk/common/header.ts", load: () => import("../lang/uk/common/header.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_common_header_ts" */), cache: true },
{ key: "../lang/uk/common/footer.ts", load: () => import("../lang/uk/common/footer.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_common_footer_ts" */), cache: true },
{ key: "../lang/uk/common/addCartPopup.ts", load: () => import("../lang/uk/common/addCartPopup.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_common_addCartPopup_ts" */), cache: true },
{ key: "../lang/uk/common/ContactSales.ts", load: () => import("../lang/uk/common/ContactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_common_ContactSales_ts" */), cache: true },
{ key: "../lang/uk/common/error.ts", load: () => import("../lang/uk/common/error.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_common_error_ts" */), cache: true },
{ key: "../lang/uk/common/cookieTip.ts", load: () => import("../lang/uk/common/cookieTip.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_common_cookieTip_ts" */), cache: true },
{ key: "../lang/uk/common/telCode.ts", load: () => import("../lang/uk/common/telCode.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_common_telCode_ts" */), cache: true },
{ key: "../lang/uk/common/SelectCountry.ts", load: () => import("../lang/uk/common/SelectCountry.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_common_SelectCountry_ts" */), cache: true },
{ key: "../lang/uk/common/formValidate.ts", load: () => import("../lang/uk/common/formValidate.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_common_formValidate_ts" */), cache: true },
{ key: "../lang/uk/common/contactUs.ts", load: () => import("../lang/uk/common/contactUs.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_common_contactUs_ts" */), cache: true },
{ key: "../lang/uk/basic/home.ts", load: () => import("../lang/uk/basic/home.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_basic_home_ts" */), cache: true },
{ key: "../lang/uk/basic/firstCategory.ts", load: () => import("../lang/uk/basic/firstCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_basic_firstCategory_ts" */), cache: true },
{ key: "../lang/uk/basic/secondCategory.ts", load: () => import("../lang/uk/basic/secondCategory.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_basic_secondCategory_ts" */), cache: true },
{ key: "../lang/uk/basic/caseStudy.ts", load: () => import("../lang/uk/basic/caseStudy.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_basic_caseStudy_ts" */), cache: true },
{ key: "../lang/uk/basic/blog.ts", load: () => import("../lang/uk/basic/blog.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_basic_blog_ts" */), cache: true },
{ key: "../lang/uk/account/checkout.ts", load: () => import("../lang/uk/account/checkout.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_account_checkout_ts" */), cache: true },
{ key: "../lang/uk/account/checkoutAgainst.ts", load: () => import("../lang/uk/account/checkoutAgainst.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_account_checkoutAgainst_ts" */), cache: true },
{ key: "../lang/uk/account/pay.ts", load: () => import("../lang/uk/account/pay.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_account_pay_ts" */), cache: true },
{ key: "../lang/uk/support/contactSales.ts", load: () => import("../lang/uk/support/contactSales.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_support_contactSales_ts" */), cache: true },
{ key: "../lang/uk/support/requestTechSupport.ts", load: () => import("../lang/uk/support/requestTechSupport.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_support_requestTechSupport_ts" */), cache: true },
{ key: "../lang/uk/support/technicalDocuments.ts", load: () => import("../lang/uk/support/technicalDocuments.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_support_technicalDocuments_ts" */), cache: true },
{ key: "../lang/uk/support/mtpDensity.ts", load: () => import("../lang/uk/support/mtpDensity.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_support_mtpDensity_ts" */), cache: true },
{ key: "../lang/uk/support/picOSVUserGuide.ts", load: () => import("../lang/uk/support/picOSVUserGuide.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_support_picOSVUserGuide_ts" */), cache: true },
{ key: "../lang/uk/support/fmtSeries.ts", load: () => import("../lang/uk/support/fmtSeries.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_support_fmtSeries_ts" */), cache: true },
{ key: "../lang/uk/support/solutionDemoTest.ts", load: () => import("../lang/uk/support/solutionDemoTest.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_support_solutionDemoTest_ts" */), cache: true },
{ key: "../lang/uk/popup/address.ts", load: () => import("../lang/uk/popup/address.ts" /* webpackChunkName: "locale__home_jenkins_agent_workspace_platform_static_32front_category_prod_lang_uk_popup_address_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "au",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/popup/address.ts"
      ]
    },
    {
      "code": "de",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/popup/address.ts"
      ]
    },
    {
      "code": "de-en",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/popup/address.ts"
      ]
    },
    {
      "code": "en",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/popup/address.ts"
      ]
    },
    {
      "code": "es",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/popup/address.ts"
      ]
    },
    {
      "code": "mx",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/popup/address.ts"
      ]
    },
    {
      "code": "fr",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/popup/address.ts"
      ]
    },
    {
      "code": "it",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/popup/address.ts"
      ]
    },
    {
      "code": "jp",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/popup/address.ts"
      ]
    },
    {
      "code": "ru",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/popup/address.ts"
      ]
    },
    {
      "code": "sg",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/popup/address.ts"
      ]
    },
    {
      "code": "uk",
      "files": [
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/header.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/footer.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/addCartPopup.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/ContactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/error.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/cookieTip.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/telCode.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/SelectCountry.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/formValidate.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/contactUs.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/basic/home.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/basic/firstCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/basic/secondCategory.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/basic/caseStudy.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/basic/blog.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/account/checkout.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/account/checkoutAgainst.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/account/pay.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/contactSales.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/requestTechSupport.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/technicalDocuments.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/mtpDensity.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/picOSVUserGuide.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/fmtSeries.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/solutionDemoTest.ts",
        "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/popup/address.ts"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "au",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/au/popup/address.ts"
      }
    ]
  },
  {
    "code": "de",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de/popup/address.ts"
      }
    ]
  },
  {
    "code": "de-en",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/de-en/popup/address.ts"
      }
    ]
  },
  {
    "code": "en",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/en/popup/address.ts"
      }
    ]
  },
  {
    "code": "es",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/popup/address.ts"
      }
    ]
  },
  {
    "code": "mx",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/es/popup/address.ts"
      }
    ]
  },
  {
    "code": "fr",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/fr/popup/address.ts"
      }
    ]
  },
  {
    "code": "it",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/it/popup/address.ts"
      }
    ]
  },
  {
    "code": "jp",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/jp/popup/address.ts"
      }
    ]
  },
  {
    "code": "ru",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/ru/popup/address.ts"
      }
    ]
  },
  {
    "code": "sg",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/sg/popup/address.ts"
      }
    ]
  },
  {
    "code": "uk",
    "files": [
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/header.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/footer.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/addCartPopup.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/ContactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/error.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/cookieTip.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/telCode.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/SelectCountry.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/formValidate.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/common/contactUs.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/basic/home.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/basic/firstCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/basic/secondCategory.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/basic/caseStudy.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/basic/blog.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/account/checkout.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/account/checkoutAgainst.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/account/pay.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/contactSales.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/requestTechSupport.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/technicalDocuments.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/mtpDensity.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/picOSVUserGuide.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/fmtSeries.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/support/solutionDemoTest.ts"
      },
      {
        "path": "/home/jenkins/agent/workspace/platform-static front-category-prod/lang/uk/popup/address.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
