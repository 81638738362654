import revive_payload_client_aaXA1lgEqS from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_5estx2tojukuyv3c5deucp6dbm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Kxa0WN3jU5 from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_5estx2tojukuyv3c5deucp6dbm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_UuMrR0Cwf4 from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_5estx2tojukuyv3c5deucp6dbm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import debug_8EPrW4lm8Q from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_5estx2tojukuyv3c5deucp6dbm/node_modules/nuxt/dist/app/plugins/debug.js";
import payload_client_3IPNCL9KAd from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_5estx2tojukuyv3c5deucp6dbm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_N6eU5SLYyf from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_5estx2tojukuyv3c5deucp6dbm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_nnFWqxZP0Q from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.26.0_typescript@5.6.3_vue@3.4.30_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/jenkins/agent/workspace/platform-static front-category-prod/.nuxt/components.plugin.mjs";
import prefetch_client_ZlheDGEpq1 from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_5estx2tojukuyv3c5deucp6dbm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_f59RQezIKW from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.26.0_vue@3.4.30_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_0ZNGLkwCHF from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.26.0_vue@3.4.30_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import chunk_reload_client_nfSuaLsVMs from "/home/jenkins/agent/workspace/platform-static front-category-prod/node_modules/.pnpm/nuxt@3.12.2_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@8.57.1_ioredis@5.4.1_magicast@0.3_5estx2tojukuyv3c5deucp6dbm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _02_detectDevice_pxm8fCuh4v from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/02.detectDevice.ts";
import _03_lazyPlugin_Dd5b7eZc4K from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/03.lazyPlugin.ts";
import _04_errorMonitor_LOgjIHx1K2 from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/04.errorMonitor.ts";
import _05_loading_directive_BLzQLP4uua from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/05.loading.directive.ts";
import _06_liveChat_client_53hhWlm58s from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/06.liveChat.client.ts";
import _07_sentry_client_fpwQ0Adq5I from "/home/jenkins/agent/workspace/platform-static front-category-prod/plugins/07.sentry.client.ts";
export default [
  revive_payload_client_aaXA1lgEqS,
  unhead_Kxa0WN3jU5,
  router_UuMrR0Cwf4,
  debug_8EPrW4lm8Q,
  payload_client_3IPNCL9KAd,
  check_outdated_build_client_N6eU5SLYyf,
  plugin_vue3_nnFWqxZP0Q,
  components_plugin_KR1HBZs4kY,
  prefetch_client_ZlheDGEpq1,
  switch_locale_path_ssr_f59RQezIKW,
  i18n_0ZNGLkwCHF,
  chunk_reload_client_nfSuaLsVMs,
  _02_detectDevice_pxm8fCuh4v,
  _03_lazyPlugin_Dd5b7eZc4K,
  _04_errorMonitor_LOgjIHx1K2,
  _05_loading_directive_BLzQLP4uua,
  _06_liveChat_client_53hhWlm58s,
  _07_sentry_client_fpwQ0Adq5I
]